<template>
  <div>
    <div class="page-heading">
      <h5>Add Community Member</h5>
    </div>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="submit">
          <div class="form-row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Full Name</label>
                <input type="text" required placeholder="Full Name" v-model="user.name" class="form-control" id="form-option-name">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Email</label>
                <input type="text" required placeholder="Email" v-model="user.email" class="form-control" id="form-option-email">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Phone Number</label>
                <input type="text" required placeholder="Phone Number" v-model="user.phone_number" class="form-control" id="form-option-phone_number">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Activate Subscription</label>
                <select v-model="user.subscription" required class="form-control" id="form-option-subscription">
                  <option v-for="(plan, i) in membership_plans" :key="`plan-${i}`" :value="plan.id">{{ plan.months }} Month{{ plan.months == 1 ? '' : 's' }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-12 mt-3">
              <button class="btn btn-secondary btn-sm"><i class="lni lni-save mr-2"></i>Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      user: {
        name: '',
        email: '',
        phone_number: ''
      },
      membership_plans: []
    }
  },

  methods: {
    fetch() {
      this.$axios.get('/api/v1/admin/traders/create').then(response => {
        this.membership_plans = response.data.membership_plans
      })
    },

    submit() {
      this.$loader.start()
      this.$axios.post('/api/v1/admin/traders', this.user).then(response => {
        this.$loader.stop()
        this.$router.push({ name: 'admin.users.show', params: { id: response.data.id }})
      }).catch(error => {
        this.$errors.handle(error)
      })
    }
  }
}
</script>