<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <h4 class="mb-0">Add New User</h4>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <select v-model="role" class="form-control">
            <option value="1">New Trader</option>
            <option value="3">New Learner</option>
            <option value="2">New Moderator</option>
          </select>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body" v-if="role == 1">
        <add-trader />
      </div>
      <div class="card-body" v-if="role == 2">
        <add-moderator />
      </div>
      <div class="card-body" v-if="role == 3">
        <add-learner />
      </div>
    </div>
  </div>
</template>

<script>
import AddTrader from './components/AddTrader.vue'
import AddModerator from './components/AddModerator.vue'
import AddLearner from './components/AddLearner.vue'

export default {
  components: {
    AddTrader, AddModerator, AddLearner
  },

  data() {
    return {
      role: 3
    }
  }
}
</script>