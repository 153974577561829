<template>
  <div>
    <div class="page-heading">
      <h5 class="text-dark mb-4">Add Academy Learner</h5>
    </div>
    <form @submit.prevent="submit">
      <div class="form-row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Full Name</label>
            <input
              type="text"
              required
              placeholder="Full Name"
              v-model="user.name"
              class="form-control"
              id="form-option-name"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Email</label>
            <input
              type="text"
              required
              placeholder="Email"
              v-model="user.email"
              class="form-control"
              id="form-option-email"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Phone Number</label>
            <input
              type="text"
              required
              placeholder="Phone Number"
              v-model="user.phone_number"
              class="form-control"
              id="form-option-phone_number"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Activate Academy Weeks</label>
            <a href="#" class="float-right text-xs" @click.prevent="show_weeks = true"><i class="lni lni-calendar mr-2"></i> View Academy Schedule</a>
            <input type="text" class="form-control" placeholder="Academy Weeks" v-model="user.weeks">
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <button class="btn btn-secondary btn-sm">
            <i class="lni lni-save mr-2"></i>Save
          </button>
        </div>
      </div>
    </form>
    <modal :show="show_weeks" @close="show_weeks = false">
      <template slot="header">Academy Schedule</template>
      <ul class="list-unstyled">
        <li v-for="(plan, i) in learner_plans" :key="`learner-${i}`">Week {{ i+1 }} - {{ plan.title }}</li>
      </ul>
    </modal>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      user: {
        name: "",
        email: "",
        phone_number: "",
        weeks: 1
      },
      learner_plans: [],
      show_weeks: false
    };
  },

  methods: {
    fetch() {
      this.$axios.get("/api/v1/admin/learners/create").then((response) => {
        this.learner_plans = response.data.weeks;
      });
    },

    submit() {
      this.$loader.start();
      
      this.$axios
        .post("/api/v1/admin/learners", this.user)
        .then((response) => {
          this.$loader.stop();
          this.$router.push({
            name: "admin.users.show",
            params: { id: response.data.id },
          });
        })
        .catch((error) => {
          this.$errors.handle(error);
        });
    },
  },
};
</script>
